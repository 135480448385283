<!-- 设备管理 -->
<template>
  <div id="device">
    <div class="tree">
      <div class="tree-title">组织结构</div>
      <el-input placeholder="输入检索内容" suffix-icon="el-icon-search" v-model.trim="filterText"></el-input>
      <!-- <el-checkbox v-model="allSelect">全选</el-checkbox> -->
      <el-tree ref="tree" v-loading="loading" :default-expand-all="false" :show-checkbox="false" :data="treeData"
        :check-on-click-node="false" :props="defaultProps" node-key="id" :filter-node-method="filterNode"
        @node-click="handleCheck"></el-tree>
    </div>
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>设备管理</span>  
      </div>
      <div class="query-form">
        <el-form class="form" ref="form" :inline="true" :model="requestParam">
          <el-form-item label="场站名称">
            <el-select filterable  v-model="requestParam.stationId" :popper-append-to-body="false" clearable placeholder="请选择场站名称">
              <el-option v-for="item in stationData" :key="item.id" :label="item.stationName +' '+ item.stationNumber"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称">
            <el-input v-model.trim="requestParam.deviceName" placeholder="请输入设备名称" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="设备编号">
            <el-input v-model.trim="requestParam.deviceNumber" placeholder="请输入设备编号" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="设备类型">
            <!-- <el-input v-model.trim="requestParam.deviceModel" placeholder="请输入设备型号" clearable> -->
              <el-select v-model="requestParam.deviceModel" :popper-append-to-body="false" clearable placeholder="请选择设备类型">
                  <el-option v-for="item in sblxData" :key="item.dictValue" :label="item.dictName"
                  :value="item.dictValue"></el-option>
              </el-select>
            <!-- </el-input> -->
          </el-form-item>
          <el-form-item label="柜子名称">
            <el-input v-model.trim="requestParam.cabinetName" placeholder="请输入柜子名称" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="安装位置">
            <el-select v-model="requestParam.position" :popper-append-to-body="false" clearable placeholder="请选择安装位置">
              <el-option v-for="item in positionData" :key="item.dictValue" :label="item.dictName"
                :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="query-button">
          <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>

          <el-button v-if="isButtonPower('createButtonEqu')" type="primary" icon="el-icon-plus"
            @click="createDialog">新建设备</el-button>
        </div>
      </div>

      <el-table class="table" :data="tableData">
        <el-table-column align="center" type="index" label="序号" width="75">
          <template slot-scope="scope">
            <span>{{
              (requestParam.pageNum - 1) * requestParam.pageSize +
              scope.$index +
              1
            }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="stationIdDesc" label="场站名称">
        </el-table-column>
        <el-table-column align="center" prop="stationNumber" label="场站编码" >
        </el-table-column>
        <el-table-column align="center" prop="deviceName" label="设备名称" >
        </el-table-column>
        <el-table-column align="center" prop="deviceNumber" label="设备编号">
        </el-table-column>
        <el-table-column align="center" prop="deviceModelName" label="设备类型">
        </el-table-column>
        <el-table-column align="center" prop="cabinetName" label="柜子名称">
        </el-table-column>
        <el-table-column align="center" prop="cabinetNumber" label="柜子编号">
        </el-table-column>
        <el-table-column align="center" prop="positionDict" label="安装位置">
        </el-table-column>
        <el-table-column align="center" prop="loopName" label="回路名称">
        </el-table-column>
        <el-table-column align="center" prop="loopNumber" label="回路编号">
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" prop="operate" width="200px">
          <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" @click="viewDialog(scope.row)">详情</el-button>
              <div v-if="isButtonPower('editButtonEqu')">
                <span>|</span>
                <el-button type="text" @click="updateDialog(scope.row)">编辑</el-button>
              </div>
              <div v-if="isButtonPower('deleteButtonEqu')">
                <span>|</span>
                <el-button type="text" @click="deleteDialog(scope.row)">删除</el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <button class="home-page" @click="homePage">首页</button>
        <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
          layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
          @size-change="changeSize" @current-change="changeCurrent">
        </el-pagination>
        <button class="tail-page" @click="tailPage">尾页</button>
        <div class="jump-pagination">
          <span class="text">跳至</span>
          <el-input v-model.number="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
            @change="inputValueChange"></el-input>
          <span class="text">页</span>
          <button class="confirm" @click="confirm">确定</button>
        </div>
      </div>
    </div>
    <el-dialog title="详情" :visible.sync="viewVisible" width="859px">
      <div class="dialog-content">
        <el-form class="dialog-form" :inline="true" :model="detailsData">
          <el-form-item label="场站名称" prop="stationId">
            <el-input class="block" v-model="detailsData.stationIdDesc" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="设备名称" prop="deviceName">
            <el-input class="block" v-model="detailsData.deviceName" placeholder="请输入设备名称" :readonly="true"></el-input>
          </el-form-item>

          <el-form-item label="设备编号" prop="deviceNumber">
            <el-input v-model="detailsData.deviceNumber" placeholder="请输入设备编号" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="设备类型" class="special" prop="deviceModelName">
            <el-input v-model="detailsData.deviceModelName" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="柜子名称" prop="cabinetName">
            <el-input v-model="detailsData.cabinetName" placeholder="请输入柜子名称" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="柜子编号" class="special" prop="cabinetNumber">
            <el-input v-model="detailsData.cabinetNumber" placeholder="请输入柜子编号" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="安装位置" prop="position">
            <el-input v-model="detailsData.positionDict" placeholder="请输入安装位置" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="回路名称" class="special" prop="loopId">
            <el-input v-model="detailsData.loopName" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="生产厂家">
            <el-input v-model="detailsData.manufacturer" placeholder="请输入生产厂家" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="生产日期" class="special">
            <el-input v-model="detailsData.manufacturerTime" placeholder="请选择生产日期" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="投运日期">
            <el-input v-model="detailsData.useTime" placeholder="请选择投运日期" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="质保截止日期" class="special">
            <el-input v-model="detailsData.warrentyTime" placeholder="请选择质保截止日期" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="设备型号">
            <el-input v-model="detailsData.deviceType" placeholder="请输入设备型号" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item v-if="deviceData.deviceModel=='dianlifenxi'"  class="special" label="额定容量(KVA)" prop="ratedCapacity" >
            <el-input v-model.trim="deviceData.ratedCapacity" placeholder="请输入额定容量"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :readonly="true" :rows="3" v-model="detailsData.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="viewVisible = false">取 消</el-button>
        <el-button type="primary" @click="viewVisible = false">确 定</el-button> -->
      </div>
    </el-dialog>
    <el-dialog :title="requestType === 0 ? '新建设备' : '修改设备'" :visible.sync="dialogVisible" width="879px"
      @close="dialogClose('deviceForm')">
      <div class="dialog-content">
        <el-form class="dialog-form" ref="deviceForm" :inline="true" :model="deviceData" :rules="rules">
          <el-form-item label="变配电站" prop="stationId">
            <el-input placeholder="请选择变配电站" v-model="stationName" :readonly="true">
              <template slot="append">
                <span @click="selectStation">选择配电站</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="设备名称" prop="deviceName">
            <el-input class="block" v-model.trim="deviceData.deviceName" placeholder="请输入设备名称"></el-input>
          </el-form-item>
          <el-form-item label="设备编号" prop="deviceNumber">
            <el-input v-model.trim="deviceData.deviceNumber" placeholder="请输入设备编号"></el-input>
          </el-form-item>
         
          <el-form-item label="设备类型" class="special" prop="deviceModel">
            <!-- <el-input v-model.trim="deviceData.deviceModel" placeholder="请输入设备型号"></el-input> -->
            <el-select v-model="deviceData.deviceModel" :popper-append-to-body="false" clearable placeholder="请选择设备类型">
                  <el-option v-for="item in sblxData" :key="item.dictValue" :label="item.dictName"
                  :value="item.dictValue"></el-option>
              </el-select>
          </el-form-item>
          <!-- <el-form-item label="柜子名称" prop="cabinetName"> -->
          <el-form-item label="柜子名称">
            <el-input v-model.trim="deviceData.cabinetName" placeholder="请输入柜子名称"></el-input>
          </el-form-item>
          <!-- <el-form-item label="柜子编号" class="special" prop="cabinetNumber"> -->
          <el-form-item label="柜子编号" class="special">
            <el-input v-model.trim="deviceData.cabinetNumber" placeholder="请输入柜子编号"></el-input>
          </el-form-item>
        
          <el-form-item label="生产厂家">
            <el-input v-model.trim="deviceData.manufacturer" placeholder="请输入生产厂家"></el-input>
          </el-form-item>
          <el-form-item label="生产日期" class="special">
            <el-date-picker v-model="deviceData.manufacturerTime" type="datetime" placeholder="请选择生产日期"
               value-format="yyyy-MM-dd HH:mm:ss" :append-to-body="false"
              @change="checkDateTime">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="投运日期">
            <el-date-picker v-model="deviceData.useTime" type="datetime" placeholder="请选择投运日期"
              value-format="yyyy-MM-dd HH:mm:ss" :append-to-body="false" :picker-options="pickerOptionsEnd"
              @change="checkDateTime">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="质保截止日期" class="special">
            <el-date-picker v-model="deviceData.warrentyTime" type="datetime" placeholder="请选择质保截止日期"
              value-format="yyyy-MM-dd HH:mm:ss" :append-to-body="false" :picker-options="pickerOptionsEnd"
              @change="checkDateTime">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="安装位置" prop="position">
            <el-select v-model="deviceData.position" :popper-append-to-body="false" clearable placeholder="请选择安装位置">
              <el-option v-for="item in positionData" :key="item.dictValue" :label="item.dictName"
                :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="requestType === 0" label="回路名称" class="special" prop="loopIdList">
            <el-select multiple  v-model="deviceData.loopIdList" :popper-append-to-body="false" clearable placeholder="请选择回路名称">
              <el-option v-for="item in circuitData" :key="item.id" :label=" item.loopName +'('+item.loopNumber+')' " :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="requestType === 1" label="回路名称" class="special" prop="loopId">
            <el-select  v-model="deviceData.loopId" :popper-append-to-body="false" clearable placeholder="请选择回路名称">
              <el-option v-for="item in circuitData" :key="item.id" :label="item.loopName+'('+item.loopNumber+')' " :value="item.id"></el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="设备型号" prop="">
            <el-input v-model.trim="deviceData.deviceType" placeholder="请输入设备型号"></el-input>
          </el-form-item>
           <el-form-item v-if="deviceData.deviceModel=='dianlifenxi'"  class="special" label="额定容量(KVA)" prop="ratedCapacity" >
            <el-input v-model.trim="deviceData.ratedCapacity" placeholder="请输入额定容量"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :rows="3" v-model.trim="deviceData.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm('deviceForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="deleteVisible" width="436px">
      <div class="dialog-text">确定要删除该数据吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteDevice">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 变配电站选择 -->
    <stationDialog :status="dialogStatus" :stationId="stationId" @selectedStation="getStationName"
      @statusChange="changeDialog"></stationDialog>
  </div>
</template>
<script>
import { deviceApi } from "@/api/device.js";
import { energyStationApi } from "@/api/energyStation.js";
import { inspectionFormApi } from "@/api/inspectionForm.js";
import stationDialog from "../operationManagement/commponents/stationDialog.vue";
export default {
  name: "",
  components: { stationDialog },
  data() {
    return {
      loading: false,
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        deviceNumber: "",
        deviceName: "",
        cabinetName: "",
        deviceModel: "",
        position: "",
        stationId: "",
        companyInfoId: "",
      },
      total: 0,
      inputValue: "",
      id: "",
      requestType: 0,
      rules: {
        stationId: [
          { required: true, message: "请选择场站名称", trigger: "change" },
        ],
        deviceName: [
          { required: true, message: "请输入设备名称", trigger: "blur" },
        ],
        deviceNumber: [
          { required: true, message: "请输入设备编号", trigger: "blur" },
        ],
        deviceModel: [
          { required: true, message: "请输入设备型号", trigger: "blur" },
        ],
        cabinetName: [
          { required: true, message: "请输入柜子名称", trigger: "blur" },
        ],
        cabinetNumber: [
          { required: true, message: "请输入柜子编号", trigger: "blur" },
        ],
        deviceModel: [
          { required: true, message: "请输入设备型号", trigger: "blur" },
        ],
        position: [
          { required: true, message: "请选择安装位置", trigger: "change" },
        ],
        loopId: [
          { required: true, message: "请选择回路名称", trigger: "change" },
        ],
        loopIdList: [
          { required: true, message: "请选择回路名称", trigger: "blur" },
        ],
        deviceType:[
          { required: true, message: "请输入设备型号", trigger: "blur" },
        ],
        ratedCapacity:[
          { required: true, message: "请输入额定容量", trigger: "blur" },
        ],
      },
      checkedNodes: [],
      defaultProps: {
        children: "companyInfoList",
        id: "id",
        label: "companyName",
      },
      treeData: [],
      filterText: "",
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      detailsData: {},
      deviceData: {
        stationId: "",
        deviceName: "",
        deviceNumber: "",
        deviceModel: "",
        cabinetName: "",
        cabinetNumber: "",
        position: "",
        loopId: "",
        loopIdList:'',
        manufacturer: "",
        manufacturerTime: "",
        useTime: "",
        warrentyTime: "",
        remark: "",
        stationNumber:'',
        deviceType:'',
        ratedCapacity:null,
      },
      defaultData: {
        stationId: "",
        deviceName: "",
        deviceNumber: "",
        deviceModel: "",
        cabinetName: "",
        cabinetNumber: "",
        position: "",
        loopId: "",
        manufacturer: "",
        manufacturerTime: "",
        useTime: "",
        warrentyTime: "",
        remark: "",
      },
      stationData: [],
      circuitData: [],
      positionData: [],
      sblxData:[],
      pickerOptionsStart: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000;
        },
      },
      //选择场站
      stationName: "",
      dialogStatus: false,
      stationId: 0,
      allButtonPower: [],
    };
  },
  created() {
    this.$store.commit("increment", "Eios控制面板");
    this.$store.commit("selectChild", "设备管理");
    this.$store.commit("selectChildren", "");
  },
  mounted() {
    this.queryUnitName();
    this.queryPosition();
    this.querySblx()
    // this.queryStationName();
    this.allButtonPower = sessionStorage.getItem('roleInfo');
  },

  methods: {
    //设备类型下拉
    querySblx(){
      energyStationApi
        .queryDictionary({ dictCode: "deviceModelType" })
        .then((res) => {
          if (res.code === 200) {
            this.sblxData = res.data;
          }
        });
    },
    //按钮权限
    isButtonPower(code) {
      if (this.allButtonPower) {
        let list = this.allButtonPower.includes(code);
        return list;
      } else {
        return false;
      }
    },
    // 选择场站
    getStationName(value) {
      console.log(value);
      if (value.id) {
        this.deviceData.stationId = value.id;
        this.deviceData.stationNumber = value.stationNumber;
        this.stationName = value.stationName;
        this.queryCircuitName();
        this.deviceData.loopIdList='';
        this.dialogStatus = false;
      }
    },
    changeDialog(value) {
      this.dialogStatus = value;
    },
    selectStation() {
      this.dialogStatus = true;
    },
    //-----------------------
    filterNode(value, data) {
      if (!value) return true;
      return data.companyName.indexOf(value) !== -1;
    },
    dialogClose(formName) {
      this.stationName = "";
      this.stationId = 0;
      this.circuitData=[]
      Object.assign(this.deviceData, this.defaultData);
      this.$refs[formName].resetFields();
    },
    createDialog() {
      this.requestType = 0;
      Object.assign(this.deviceData, this.defaultData);
      this.dialogVisible = true;
    },
    updateDialog(data) {
      this.requestType = 1;
      this.id = data.id;
      this.stationName = data.stationIdDesc;
      this.deviceData.stationId = data.stationId;
      this.deviceData.stationNumber = data.stationNumber;
      this.queryCircuitName();
      Object.assign(this.deviceData, data);
      this.dialogVisible = true;
    },
    viewDialog(data) {
      Object.assign(this.detailsData, data);
      this.viewVisible = true;
    },
    deleteDialog(item) {
      this.id = item.id;
      this.deleteVisible = true;
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryDevice();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //获取停电时长
    checkDateTime() {
      if (this.deviceData.manufacturerTime && this.deviceData.warrentyTime) {
        const startTime = new Date(this.deviceData.manufacturerTime).getTime();
        const endTime = new Date(this.deviceData.warrentyTime).getTime();
        if (startTime == endTime) {
          this.$message({
            message: "生产日期与质保截止日期不能相同",
            type: "warning",
            duration: 3000,
            customClass: "messageText",
          });
          this.deviceData.warrentyTime = null;
        } else if (startTime > endTime) {
          this.$message({
            message: "生产日期不能大于质保截止日期",
            type: "warning",
            duration: 3800,
            customClass: "messageText",
          });
          this.deviceData.warrentyTime = null;
        }
      }
      if (this.deviceData.manufacturerTime && this.deviceData.useTime) {
        const startTime = new Date(this.deviceData.manufacturerTime).getTime();
        const endTime = new Date(this.deviceData.useTime).getTime();
        if (startTime == endTime) {
          this.$message({
            message: "生产日期与投运日期不能相同",
            type: "warning",
            duration: 3000,
            customClass: "messageText",
          });
          this.deviceData.useTime = null;
        } else if (startTime > endTime) {
          this.$message({
            message: "生产日期不能大于投运日期",
            type: "warning",
            duration: 3800,
            customClass: "messageText",
          });
          this.deviceData.useTime = null;
        }
      }
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryDevice();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryDevice();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryDevice();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryDevice();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryDevice();
    },
    dialogConfirm(formName) {
      console.log(this.deviceData);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.requestType === 0) {
            this.createDevice();
          } else {
            this.updateDevice();
          }
        } else {
          return false;
        }
      });
    },
    handleCheck(checkedNodes, event) {
      if (this.requestParam.stationId) {
        this.requestParam.stationId = "";
      }
      if (this.requestParam.companyInfoId == checkedNodes.id) {
        this.checkedNodes=''
        this.requestParam.companyInfoId=''
        const currentNode = this.$refs.tree.$el.querySelector('.is-current');
          if (currentNode) {
            currentNode.classList.remove('is-current');
          } 
          this.queryDevice();
          this.queryStation(this.checkedNodes.id);
      }else{
        this.checkedNodes = checkedNodes;
        this.requestParam.companyInfoId = checkedNodes.id;
        this.queryDevice();
        this.queryStation(this.checkedNodes.id);
      }
    
    },
    queryDevice() {
      deviceApi.queryDevice(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    queryStation(companyId) {
      energyStationApi.queryStation({companyId:companyId}).then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
        }
      });
    },
    // 获取安装位置
    queryPosition() {
      energyStationApi
        .queryDictionary({ dictCode: "position_type" })
        .then((res) => {
          if (res.code === 200) {
            this.positionData = res.data;
          }
        });
    },
    // 构建
    buildTreeData(data) {
      data.forEach((item, index) => {
        if (item.companyInfoList.length !== 0) {
          this.buildTreeData(item.companyInfoList);
        } else {
          let array = [];
          item.stationInfoList.forEach((element) => {
            if (element !== null) {
              array.push({
                id: element.id,
                companyName: element.stationName,
                final: true,
              });
              item.companyInfoList = array;
            }
          });
        }
      });
      return data;
    },
    queryUnitName() {
      this.loading = true;
      deviceApi.queryUnitName().then((res) => {
        this.loading = false;
        if (res.code === 200) {
          // this.requestParam.companyInfoId = res.data[0].id;
          this.queryDevice();
          this.treeData = res.data;
          this.queryStation();
          // this.queryStation(res.data[0].id);
          // this.$nextTick(() => {
          //   this.$refs.tree.setCurrentKey(this.treeData[0].id); // 默认选中节点第一个
          // });
        }
      });
    },
    queryCircuitName() {
      let obj = {
        stationId: this.deviceData.stationId
      }
      deviceApi.queryCircuitName(obj).then((res) => {
        if (res.code === 200) {
          this.circuitData = res.data;
          if (this.circuitData.length == 0) {
            if (this.deviceData.loopId) {
              this.deviceData.loopId = ''
            }
          }else{
            let idArr=this.circuitData.map(item=>item.id)
            if(!idArr.includes(this.deviceData.loopId)){
              this.deviceData.loopId = ''
            }
          }
        }
      });
    },
    createDevice() {
      this.deviceData.ratedCapacity=parseInt(this.deviceData.ratedCapacity)
      deviceApi.createDevice(this.deviceData).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.dialogVisible = false;
          this.queryDevice();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    updateDevice() {
      this.deviceData.id = this.id;
      this.deviceData.loopIdList = null;
      this.deviceData.ratedCapacity=parseInt(this.deviceData.ratedCapacity)
      deviceApi.updateDevice(this.deviceData).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.dialogVisible = false;
          this.queryDevice();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    deleteDevice() {
      deviceApi.deleteDevice(this.id).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryDevice();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    "deviceData.stationId": {
      // 执行方法
      handler(newValue, oldValue) {
        if (newValue) {
          this.stationId = newValue;
        }
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
  },
  computed: {
    pickerOptionsEnd() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.deviceData.manufacturerTime);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#device {
  width: 100%;
  min-height: 781px;
  font-size: 16px;
  display: flex;
}

.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}

.main {
  width: calc(100% - 240px);
  background: url("../../assets/images/content-background.png") no-repeat center;
  background-size: cover;
  padding: 22px 0 26px 30px;
  box-sizing: border-box;
}

/* 弹窗 */
.dialog-content {
  margin: 34px 46px 0 60px;
}

/* input */
.dialog-form :deep() .el-date-editor.el-input {
  width: 260px;
}

.dialog-form :deep() .el-input {
  width: 260px;
}

.dialog-form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 100%;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-textarea textarea {
  width: 655px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-textarea textarea::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.dialog-form :deep() .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.dialog-form :deep() .special {
  margin-left: 25px;
}

.dialog-form :deep() .block {
  width: 655px;
}

.dialog-form :deep() .block .el-input {
  width: 655px;
}

/* 时间选择器 */
/*时间日期选择器默认样式修改 */
.dialog-form :deep() .el-picker-panel .el-input {
  width: 100%;
}

::v-deep .el-time-panel {
  background: #041c4c;
  border: 0;
  color: #aed6ff;
}

::v-deep .el-time-panel__footer {
  border-color: #aed6ff;
}

::v-deep .el-time-spinner__item {
  color: #aed6ff;
}

::v-deep .el-time-spinner__item.active:not(.disabled) {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn.confirm {
  color: #409eff;
}

::v-deep .el-picker-panel__footer {
  color: #aed6ff;
  background: #041c4c;
}

::v-deep .is-plain {
  background: #176ad2;
  border: 0;
  color: #fff;
}

::v-deep .el-time-spinner__item:hover:not(.disabled):not(.active) {
  background: #041c4c;
}

::v-deep .is-plain:hover {
  background: #176ad2;
  border: 0;
  color: #fff;
}

/* 选择场站样式 */
.dialog-form :deep() .el-input-group__append {
  background: #143168;
  color: #fff;
  border: 0;
  cursor: pointer;
}
.dialog-form:deep() .el-form-item__label{
  width: 98px;
}
</style>
